import { createQueryKeyStore } from "@lukemorales/query-key-factory";
import {
    ClientSearchArgs,
    EngagementActivitySearchArgs,
    EngagementEventSearchArgs,
    EngagementSearchArgs,
    ProjectEventSearchArgs,
    ProjectSearchArgs,
    SearchUsersArgs,
    SearchUserProjectsArgs,
} from "./api.ts";

export const queryKeys = createQueryKeyStore({
    regions: {
        all: null,
        lookup: null,
    },
    regionStorages: {
        all: null,
        lookup: null,
    },
    clients: {
        all: null,
        byId: (clientId: string) => [clientId],
        search: (searchParams: ClientSearchArgs) => [searchParams],
    },
    engagements: {
        byClientId: (clientId: string) => [clientId],
        byEngagementId: (engagementId: string) => [engagementId],
        summaryByEngagementId: (engagementId: string, projectIds: string) => [engagementId, projectIds],
        search: (searchParams: EngagementSearchArgs) => [searchParams],
        rollup: null,
    },
    projects: {
        byProjectId: (projectId: string) => [projectId],
        byEngagementId: (engagementId: string) => [engagementId],
        byProjectCode: (projectCode: string) => [projectCode],
        summaryByProjectId: (engagementId: string, projectId: string) => [engagementId, projectId],
        search: (searchParams: ProjectSearchArgs) => [searchParams],
        userProject: (userProjectSearchParams: SearchUserProjectsArgs) => [userProjectSearchParams],
        allEngProjects: null,
    },
    engagementRequests: {
        byEngagementId: (engagementId: string, projectIds: string) => [engagementId, projectIds],
        byRequestId: (engagementId: string, requestId: string) => [engagementId, requestId],
    },
    projectRequests: {
        byProjectId: (engagementId: string, projectId: string) => [engagementId, projectId],
        byRequestId: (engagementId: string, projectId: string, requestId: string) => [engagementId, projectId, requestId],
    },
    engagementTaxNotices: {
        byEngagmentId: (engagementId: string) => [engagementId],
        byTaxNoticeId: (engagementId: string, taxNoticeId: string) => [engagementId, taxNoticeId],
    },
    projectTaxNotices: {
        byProjectId: (engagementId: string, projectId: string) => [engagementId, projectId],
        byTaxNoticeId: (engagementId: string, projectId: string, taxNoticeId: string) => [engagementId, projectId, taxNoticeId],
    },
    engagementEvents: {
        search: (engagementId: string, searchParams: EngagementEventSearchArgs | object) => [engagementId, searchParams],
        byEventId: (engagementId: string, eventId: string) => [eventId],
    },
    projectEvents: {
        search: (engagementId: string, projectId: string, searchParams: ProjectEventSearchArgs | object) => [engagementId, projectId, searchParams],
        byEventId: (engagementId: string, projectId: string, eventId: string) => [eventId],
    },
    users: {
        current: null,
        gdprConsent: null,
        byEngagementId: (engagementId: string, projectId?: string) => [engagementId, projectId],
        byProjectId: (engagementId: string, projectId?: string) => [engagementId, projectId],
        search: (args: SearchUsersArgs) => [args],
    },
    navItems: {
        all: null,
        byEngagementId: (engagementId: string) => [engagementId],
        byProjectId: (engagementId: string, projectId: string) => [engagementId, projectId],
    },
    roles: {
        all: null,
        myRoleByEngagementId: (engagementId: string) => [engagementId],
        myRoleByProjectId: (projectId: string) => [projectId],
    },
    permissions: {
        all: null,
    },
    engagementDocuments: {
        documentsByFolder: (engagementId: string, folderId: string) => [engagementId, folderId],
        documentPreviewLink: (engagementId: string, documentId: string | undefined) => [engagementId, documentId],
        documentBoxLink: (engagementId: string, folderId: string | undefined) => [engagementId, folderId],
    },
    projectDocuments: {
        documentsByFolder: (engagementId: string, projectId: string, folderId: string) => [engagementId, projectId, folderId],
        documentPreviewLink: (engagementId: string, projectId: string, documentId: string | undefined) => [engagementId, projectId, documentId],
        documentBoxLink: (engagementId: string, projectId: string, folderId: string | undefined) => [engagementId, projectId, folderId],
    },
    engagementActivity: {
        search: (engagementId: string, searchParams: EngagementActivitySearchArgs) => [engagementId, searchParams],
    },
    projectActivity: {
        search: (engagementId: string, projectId: string, searchParams: EngagementActivitySearchArgs) => [engagementId, projectId, searchParams],
    },
    engagementTypes: {
        all: null,
    },
    projectTypes: {
        all: null,
    },
    requestTypes: {
        all: null,
    },
    engagementActivityTypes: {
        all: null,
    },
    projectActivityTypes: {
        all: null,
    },
    contactTypes: {
        all: null,
    },
    requestStatuses: {
        all: null,
    },
    states: {
        all: null,
    },
    featureFlags: {
        all: null,
    },
    notificationSettings: {
        options: null,
        settings: null,
        engagementSettings: (engagementId: string) => [engagementId],
        projectSettings: (engagementId: string, projectId: string) => [engagementId, projectId],
    },
    requestConversations: {
        byRequestId: (requestId: string) => [requestId],
    },
    reports: {
        byEngId: (engagementId: string) => [engagementId],
        byProjId: (engagementId: string, projectId?: string) => [engagementId, projectId],
    },
    folders: {
        byId: (engagementId: string, projectId?: string) => [engagementId, projectId],
    },
    applications: {
        byId: (engagementId: string, projectId?: string) => [engagementId, projectId],
    },
    deliverables: {
        byId: (engagementId: string, projectId?: string) => [engagementId, projectId],
        projApprovers: (engagementId: string, projectId?: string) => [engagementId, projectId],
        engApprovers: (engagementId: string) => [engagementId],
    },
    workspace: {
        byEngId: (engagementId: string) => [engagementId],
        byProjId: (engagementId: string, projectId?: string) => [engagementId, projectId],
    },
    questionnaires: {
        byDynamicFormDefinitionId: (dynamicFormDefinitionId: string) => [dynamicFormDefinitionId],
        engDefinitions: (engagementId: string, projectId?: string) => [engagementId, projectId],
        projDefintions: (engagementId: string, projectId?: string) => [engagementId, projectId],
    },
    feedback: {
        byId: null,
    },
    vatSummary: {
        byEngId: (engagementId: string) => [engagementId],
        byProjId: (engagementId: string, projectId?: string) => [engagementId, projectId],
    },
});
