import { FunctionComponent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BaseQuestionnairePage } from "../EngagementQuestionnairePage/BaseQuestionnairePage";

const ProjectQuestionnairePage: FunctionComponent = () => {
    const { engagementId, projectId, dynamicFormId } = useParams();
    const navigate = useNavigate();

    const navigateBack = () => {
        navigate(`/engagement/${engagementId}/project/${projectId}/questionnaires/`, { relative: "path" });
    };

    return <BaseQuestionnairePage engagementId={engagementId || ""} dynamicFormDefinitionId={dynamicFormId || ""} goBack={navigateBack} />;
};

export default ProjectQuestionnairePage;
