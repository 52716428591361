import { FunctionComponent, useState } from "react";

import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Flex, Button } from "@chakra-ui/react";
import { IconCirclePlus } from "@tabler/icons-react";
import { JSONSchemaType } from "ajv";
import { DynamicForm, ExpandedJSONSchema7 } from "am-tax-fe-core";

import { Application, useUpdateApplication } from "../../api";

interface Props {
    engagementId: string;
    projectId?: string;
    application: Application;
    show: boolean;
    closeDialog: () => void;
}

type SchemaData = JSONSchemaType<ExpandedJSONSchema7>;

const ActivateApplicationDialog: FunctionComponent<Props> = ({ engagementId, projectId, application, show, closeDialog }: Props) => {
    const { mutate: updateApplication, isPending: enableAppIsPending } = useUpdateApplication();

    const schema = application.schema as string;
    const formSchema = JSON.parse(schema) as JSONSchemaType<ExpandedJSONSchema7>;

    const defaultValues = application.data ? (JSON.parse(application.data) as JSONSchemaType<SchemaData>) : undefined;
    const [schemaData, setSchemaData] = useState(defaultValues);

    const [isValid, setIsValid] = useState(false);
    const [highlightErrors, setHighlightErrors] = useState(false);

    const handleClose = () => {
        closeDialog();
    };

    const onIsValid = (isValidVal: boolean) => {
        setIsValid(isValidVal);
    };

    const onChange = (fields: JSONSchemaType<SchemaData>) => {
        if (isValid) {
            setSchemaData(fields);
        }
    };

    // const onValidation = (fields?: FieldNamesMarkedBoolean<JSONSchemaType<JSONSchemaType<ExpandedJSONSchema7>>>) => {
    //     console.log(fields);
    // };

    const handleSaveBtnClick = () => {
        if (isValid) {
            const args = {
                ...application,
                engagementId,
                projectId,
                data: JSON.stringify(schemaData),
                enabled: true,
            };
            updateApplication(args);
            closeDialog();
        } else {
            setHighlightErrors(true);
        }
    };

    return (
        <Modal isOpen={show} onClose={handleClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Activate Application</ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <DynamicForm
                        defaultValues={defaultValues}
                        formSchema={formSchema}
                        onChange={onChange}
                        onIsValid={onIsValid}
                        highlightErrors={highlightErrors}
                        hideActions={true}
                    />
                </ModalBody>

                <ModalFooter px="1rem">
                    <Flex width="100%" justify="flex-end" align="center" gap="0.5rem">
                        {application.enabled ? (
                            <Button colorScheme="blue" onClick={handleSaveBtnClick}>
                                Save
                            </Button>
                        ) : (
                            <Button
                                variant="outline"
                                colorScheme="green"
                                rightIcon={<IconCirclePlus />}
                                onClick={handleSaveBtnClick}
                                isLoading={enableAppIsPending}
                            >
                                Activate
                            </Button>
                        )}
                        <Button type="button" variant="ghost" onClick={handleClose}>
                            Cancel
                        </Button>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ActivateApplicationDialog;
