import { FunctionComponent } from "react";

import { Button, Card } from "@chakra-ui/react";
import { DynamicForm, ExpandedJSONSchema7, PageLoading } from "am-tax-fe-core";
import { JSONSchemaType } from "ajv";

import {
    useCreateEngagementFormData,
    useCreateProjectFormData,
    useEngagementFormData,
    useEngagementFormDefinitions,
    useProjectFormData,
    useProjectFormDefinitions,
    useUpdateEngagementFormData,
    useUpdateProjectFormData,
} from "../../api/questionnaires/api-queries.ts";
import { Page } from "../Page.tsx";

export interface QuestionnaireProps {
    engagementId: string;
    projectId?: string | undefined;
    dynamicFormDefinitionId: string;
    goBack: () => void;
}

type SchemaData = JSONSchemaType<ExpandedJSONSchema7>;

export const BaseQuestionnairePage: FunctionComponent<QuestionnaireProps> = ({
    engagementId,
    projectId,
    dynamicFormDefinitionId,
    goBack,
}: QuestionnaireProps) => {
    const { data: engagementSchemas, isLoading: isEngFormDefLoading } = useEngagementFormDefinitions(engagementId, !projectId);
    const { data: projectSchemas, isLoading: isProjFormDefLoading } = useProjectFormDefinitions(engagementId, projectId!);
    const formSchemas = engagementSchemas ?? projectSchemas;
    const isLoadingFormDefintions = isEngFormDefLoading || isProjFormDefLoading;
    const schema = formSchemas?.find(x => x.id === dynamicFormDefinitionId)?.schema;
    const formSchema = schema ? (JSON.parse(schema) as JSONSchemaType<ExpandedJSONSchema7>) : undefined;

    const { data: engSchemaData, isLoading: isEngFormDataLoading } = useEngagementFormData(engagementId, dynamicFormDefinitionId, !projectId);
    const { data: projSchemaData, isLoading: isProjFormDataLoading } = useProjectFormData(engagementId, dynamicFormDefinitionId, projectId);
    const isFormDataLoading = isEngFormDataLoading || isProjFormDataLoading;

    const dynamicFormResponse = engSchemaData || projSchemaData;
    const formData = dynamicFormResponse?.data;
    const defaultValues = formData ? (JSON.parse(formData) as JSONSchemaType<SchemaData>) : undefined;

    const { mutate: createProjectFormData, isPending: createProjectFormDataPending } = useCreateProjectFormData();
    const { mutate: createEnagementFormData, isPending: createEngagementFormDataPending } = useCreateEngagementFormData();

    const { mutate: updateProjectFormData, isPending: updateProjectFormDataPending } = useUpdateProjectFormData();
    const { mutate: updateEngagementFormData, isPending: updateEngagementFormDataPending } = useUpdateEngagementFormData();

    const onSubmit = async (fields: JSONSchemaType<SchemaData>) => {
        if (dynamicFormResponse?.id) {
            if (projectId) {
                await updateProjectFormData({ engagementId, projectId, dynamicFormId: dynamicFormResponse?.id, data: JSON.stringify(fields) });
            } else {
                await updateEngagementFormData({ engagementId, dynamicFormId: dynamicFormResponse?.id, data: JSON.stringify(fields) });
            }
        } else {
            if (projectId) {
                await createProjectFormData({ engagementId, projectId, dynamicFormDefinitionId, data: JSON.stringify(fields) });
            } else {
                await createEnagementFormData({ engagementId, dynamicFormDefinitionId, data: JSON.stringify(fields) });
            }
        }
    };

    const isLoading = isLoadingFormDefintions || isFormDataLoading;
    const isUpdating = createProjectFormDataPending || createEngagementFormDataPending || updateProjectFormDataPending || updateEngagementFormDataPending;

    return (
        <Page
            title="Questionnaire"
            gutter={
                <Button variant="outline" onClick={goBack}>
                    Back to Questionnaires
                </Button>
            }
        >
            {isLoading && <PageLoading text={"Loading..."} />}

            {formSchema && !isLoading && (
                <Card p="1rem" maxWidth="1440px">
                    <DynamicForm formSchema={formSchema} defaultValues={defaultValues} onSubmit={onSubmit} submitting={isUpdating} />
                </Card>
            )}
        </Page>
    );
};
