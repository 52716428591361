import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../queryKeys";
import {
    getEngagementFormDefinitions,
    getProjectFormDefinitions,
    getEngagementFormData,
    getProjectFormData,
    createEngagementDynamicForm,
    updateEngagementDynamicForm,
    createProjectDynamicForm,
    updateProjectDynamicForm,
} from "./api";
import { mutationErrorHandler, useToast } from "am-tax-fe-core";

export function useEngagementFormDefinitions(engagementId: string, enabled = true) {
    return useQuery({
        ...queryKeys.questionnaires.engDefinitions(engagementId),
        enabled: enabled && !!engagementId,
        queryFn: () => getEngagementFormDefinitions(engagementId),
    });
}

export function useProjectFormDefinitions(engagementId: string, projectId: string) {
    return useQuery({
        ...queryKeys.questionnaires.projDefintions(engagementId, projectId),
        enabled: !!engagementId && !!projectId,
        queryFn: () => getProjectFormDefinitions(engagementId, projectId),
    });
}

export function useEngagementFormData(engagementId: string, dynamicFormDefinitionId: string, enabled = true) {
    return useQuery({
        //queryKey: ["my-unique-id"],
        ...queryKeys.questionnaires.byDynamicFormDefinitionId(dynamicFormDefinitionId),
        enabled: enabled && !!engagementId,
        queryFn: () => getEngagementFormData(engagementId, dynamicFormDefinitionId),
    });
}

export function useProjectFormData(engagementId: string, dynamicFormDefinitionId: string, projectId?: string) {
    return useQuery({
        ...queryKeys.questionnaires.byDynamicFormDefinitionId(dynamicFormDefinitionId),
        enabled: !!engagementId && !!projectId && !!dynamicFormDefinitionId,
        queryFn: () => getProjectFormData(engagementId, projectId ?? "", dynamicFormDefinitionId),
    });
}

export function useCreateEngagementFormData() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: createEngagementDynamicForm,
        onSuccess: async () => {
            toast({ title: "Success", description: "Questiononnaire submitted successfully", status: "success", duration: 3000, isClosable: true });
            // await queryClient.invalidateQueries({ queryKey: ["my-unique-id"] });
            await queryClient.invalidateQueries({ queryKey: [queryKeys.questionnaires.byDynamicFormDefinitionId] });
        },
        ...mutationErrorHandler(toast, "Unable to create engagement dynamic form"),
    });
}

export function useUpdateEngagementFormData() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: updateEngagementDynamicForm,
        onSuccess: async () => {
            toast({ title: "Success", description: "Questiononnaire updated successfully", status: "success", duration: 3000, isClosable: true });
            // await queryClient.invalidateQueries({ queryKey: ["my-unique-id"] });
            await queryClient.invalidateQueries({ queryKey: [queryKeys.questionnaires.byDynamicFormDefinitionId] });
        },
        ...mutationErrorHandler(toast, "Unable to update engagement dynamic form"),
    });
}

export function useCreateProjectFormData() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: createProjectDynamicForm,
        onSuccess: async () => {
            toast({ title: "Success", description: "Questiononnaire submitted successfully", status: "success", duration: 3000, isClosable: true });
            await queryClient.invalidateQueries({ queryKey: [queryKeys.questionnaires.byDynamicFormDefinitionId] });
        },
        ...mutationErrorHandler(toast, "Unable to create project dynamic form"),
    });
}

export function useUpdateProjectFormData() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: updateProjectDynamicForm,
        onSuccess: async () => {
            toast({ title: "Success", description: "Questiononnaire updated successfully", status: "success", duration: 3000, isClosable: true });
            await queryClient.invalidateQueries({ queryKey: [queryKeys.questionnaires.byDynamicFormDefinitionId] });
        },
        ...mutationErrorHandler(toast, "Unable to update project dynamic form"),
    });
}
