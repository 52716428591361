import React from "react";
import ReactDOM from "react-dom/client";
import { onlineManager } from "@tanstack/react-query";
import { theme } from "./theme";
import { AppConfigurationProvider, bool, defaultLocalizationStorageImpl, Fonts, getLocaleParts, getUserLocale, localizationService } from "am-tax-fe-core";
import { ChakraProvider } from "@chakra-ui/react";
import { Middleware } from "openapi-fetch";
import { configureApiClient } from "./api";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { UnauthenticatedLandingPage } from "./pages/UnauthenticatedLandingPage";
import { PageRoutes } from "./pages";
import { initializeI18n } from "./initializeI18n.ts";
import { gdprConsentStorageService } from "./gdpr/GdprConsentStorageService.ts";

//import the fe-core style sheet (fe-core bundles up all the stylesheets that are included from third party libs and exports them as a single css file)
import "am-tax-fe-core/index.css";

function configureHttpClient(baseUrl: string, getAccessToken: () => Promise<string>) {
    const msalMiddleware: Middleware = {
        async onRequest({ request }) {
            if (!bool(import.meta.env.VITE_BYPASS_AUTH)) {
                const accessToken = await getAccessToken();
                request.headers.append("Authorization", `Bearer ${accessToken}`);
            }
            return request;
        },
    };

    configureApiClient(baseUrl, [msalMiddleware]);
}

(async function () {
    if (bool(import.meta.env.VITE_BYPASS_AUTH) || bool(import.meta.env.VITE_MOCK_PARTIAL_BACKEND) || bool(import.meta.env.VITE_MOCK_ENTIRE_BACKEND)) {
        // this probably seems backwards, but but we want to tell react query to act like it is online even if it is offline
        onlineManager.setOnline(true);

        const setup = await import("./api/msw/setupForBrowser.ts");
        const { worker } = setup;
        await worker.start();
    }

    // initialize the localization service with the supported languages
    await localizationService.init(defaultLocalizationStorageImpl, ["ar", "en-US", "en-AE", "fr", "zh-CN"]);

    // initialize the translation service with the user's language pref
    const i18n = await initializeI18n(getLocaleParts(getUserLocale()).lang);

    // set up the event handler to update i18Next when the user changes their language settings
    localizationService.onSettingsChanged(settings => {
        i18n.changeLanguage(getLocaleParts(settings?.userLocale).lang || "en");
    });

    const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

    root.render(
        <React.StrictMode>
            <ChakraProvider theme={theme} resetCSS>
                <Fonts />
                <AppConfigurationProvider
                    envJsonUrl={"/env.json"}
                    byPassAuth={bool(import.meta.env.VITE_BYPASS_AUTH) || bool(import.meta.env.VITE_MOCK_ENTIRE_BACKEND)}
                    unauthenticatedLandingPage={<UnauthenticatedLandingPage />}
                    onApiContextInit={configureHttpClient}
                    enableAppInsights={bool(import.meta.env.VITE_ENABLE_APP_INSIGHTS)}
                    version={{
                        ver: import.meta.env.VITE_BUILD_COMMIT,
                        build: import.meta.env.VITE_BUILD_NUMBER,
                    }}
                    apiErrorHandlerOptions={{
                        preferFallbackMessage: true,
                    }}
                    gdprConsentStorageService={gdprConsentStorageService}
                >
                    <PageRoutes />
                    {bool(import.meta.env.VITE_ENABLE_REACT_QUERY_DEV_TOOLS) && <ReactQueryDevtools initialIsOpen={false} />}
                </AppConfigurationProvider>
            </ChakraProvider>
        </React.StrictMode>,
    );
})();
