import { FunctionComponent } from "react";
import { BaseQuestionnairePage } from "./BaseQuestionnairePage";
import { useNavigate, useParams } from "react-router-dom";

const EngagementQuestionnairePage: FunctionComponent = () => {
    const { engagementId, dynamicFormId } = useParams();
    const navigate = useNavigate();

    const navigateBack = () => {
        navigate(`/engagement/${engagementId}/questionnaires/`, { relative: "path" });
    };

    return <BaseQuestionnairePage engagementId={engagementId || ""} dynamicFormDefinitionId={dynamicFormId || ""} goBack={navigateBack} />;
};

export default EngagementQuestionnairePage;
